// extracted by mini-css-extract-plugin
export var blogList = "blog-module--blogList--qkH8k";
export var blogPageBlogPost = "blog-module--blogPageBlogPost--25Q1E";
export var blogPost = "blog-module--blogPost--QDYDy";
export var blogTextContainer = "blog-module--blogTextContainer--VcY2i";
export var container = "blog-module--container--L7Usw";
export var featuredImage = "blog-module--featuredImage--Rrod3";
export var postExcerpt = "blog-module--postExcerpt--jSP4d";
export var postPreview = "blog-module--postPreview--W42uQ";
export var postTitle = "blog-module--postTitle--PGgaa";
export var readMoreLink = "blog-module--readMoreLink--AtQHv";