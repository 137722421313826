import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql, useStaticQuery } from "gatsby"
import * as BlogStyles from "../styles/blog.module.scss"
import Hero from "../components/hero"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
        nodes {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            featured
          }
        }
      }
      blogHeroImage: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/blog-hero" }) {
        secure_url
      }
      blogImages: allCloudinaryMedia(filter: { folder: { eq: "pivot-painters-chicago/blog" } }) {
        edges {
          node {
            public_id
            secure_url
          }
        }
      }
    }
  `)
  const { nodes: posts } = data.allMdx
  const getMatchingImage = featuredImage => {
    let t = data.blogImages.edges.find(x => x.node.public_id === featuredImage)
    return t
  }

  return (
    <Layout>
      <SEO
        title="Blog"
        description="Pivot Pianters Chicago Painting Service Blog"
      />
      <Hero
        name="Our Blog"
        subtitle="To Help You And Your Home"
        src={data.blogHeroImage.secure_url}
      />
      <div className={BlogStyles.container}>
        <ul className={BlogStyles.blogList}>
          {posts
            .filter(post => post.frontmatter.title.length > 0)
            .map(post => {
              return (
                <li
                  className={`${BlogStyles.blogPost} ${BlogStyles.blogPageBlogPost}`}
                  key={post.id}
                >
                  <div className={BlogStyles.postPreview}>
                    {getMatchingImage(post.frontmatter.featured) !== null && (
                      <div className={BlogStyles.featuredImage}>
                        <Link to={post.frontmatter.path}>
                          <img
                            alt={post.frontmatter.title}
                            src={
                              getMatchingImage(post.frontmatter.featured).node
                                .secure_url
                            }
                          />
                        </Link>
                      </div>
                    )}

                    <Link to={post.frontmatter.path}>
                      <p className={BlogStyles.postTitle}>
                        {post.frontmatter.title}
                      </p>

                      <p>{post.frontmatter.date}</p>
                    </Link>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    </Layout>
  )
}

export default BlogPage
